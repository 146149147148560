import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding-top: ${props => props.size}px;
`;

/* eslint-disable react/prefer-stateless-function */
class Spacing extends Component {
  static propTypes = {
    size: PropTypes.number,
  };

  static defaultProps = {
    size: 20,
  };

  render() {
    const { size } = this.props;
    return <Wrapper size={size} />;
  }
}

export default Spacing;
